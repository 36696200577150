<script lang="ts">
    import { Card, CardBody, CardHeader, CardTitle, Input, ListGroup, ListGroupItem } from "sveltestrap/src";
    import QuoteInfo from "./components/QuoteInfo.svelte";

    const url = new URL(window.location.toString());
    
    let fromCur = url.searchParams.get("from") || "AUD";
    let toCur = url.searchParams.get("to") || "JPY";

    // return type is incorrect!!
    const getQuotes = async (): Promise<Quote[]> => {
        return await fetch(
            `https://fx.eq.workers.dev/pair/${fromCur}/${toCur}`,
            {method: "POST"}
        ).then(r => r.json());
    }

    // gets the amount from converting 1 unit of currency
    const getRate = (q: Quote) => {
        // fees are subtracted from the starting amount for Wise
        // TODO account for flat fees
        return (1 - q.percentFee) * q.rate;
    }

    const sortQuotes = (quotes: Quote[]) => {
        // generate array of [rate, quote]
        const ratePairs: [number, Quote][] = []
        for (const exName in quotes) {
            ratePairs.push([getRate(quotes[exName]), quotes[exName]]);
        }

        const sorted = ratePairs
            .sort(([left], [right]) => right - left)
            .map(([_, q]) => q);
        
        console.log(sorted);

        return sorted;
    }

    const getQuotesSorted = async () => {
        const rates = await getQuotes();
        return sortQuotes(rates);
    }

    // fire our fetch req
    let promise = getQuotesSorted();

    // input delay so we're not spamming requests on every change
    // and also add currency pair to query
    let delay: number | null = null;

    const curBoxUpdate = (e: Event) => {
        if (delay !== null) clearTimeout(delay);
        delay = setTimeout(() => {
            promise = getQuotesSorted();
            url.searchParams.set("from", fromCur);
            url.searchParams.set("to", toCur);
            history.pushState({}, '', url);
        }, 200);
    }
</script>

<Card class="mt-3 m-auto" style="max-width: min(90vw, 500px)">
    <CardHeader>
        <h1>fx</h1>
    </CardHeader>
    <ListGroup flush>
        <ListGroupItem class="d-flex justify-content-center">
            <Input
                type="text" 
                class="me-3" 
                style="width: 5em; text-align: center" 
                bind:value={fromCur} 
                on:change={curBoxUpdate} 
            />
            <Input 
                type="text" 
                style="width: 5em; text-align: center" 
                bind:value={toCur} 
                on:change={curBoxUpdate} 
            />
        </ListGroupItem>
    </ListGroup>
{#await promise}
    <CardBody>waiting...</CardBody>
{:then quotes}
    <CardBody>({quotes[0].fromCur}/{quotes[0].toCur}) the winner is {quotes[0].exchange}!</CardBody>
    <ListGroup flush>
    {#each quotes as q (q.exchange)}
        <QuoteInfo quote={q} />
    {/each}
    </ListGroup>
{:catch err}
    <CardBody class="text-danger">{err}</CardBody>
{/await}
</Card>
