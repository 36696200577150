<script lang="ts">
    import { ListGroupItem } from "sveltestrap/src";

    // TODO lol
    const getRate = (q: Quote) => {
        // fees are subtracted from the starting amount for Wise and Revolut
        // TODO account for flat fees
        return (1 - q.percentFee) * q.rate;
    }

    export let quote: Quote;
</script>

<style>

</style>

<ListGroupItem class="d-flex justify-content-between align-items-center">
    <div class="fw-bold">{quote.exchange}</div>
    <div class="d-flex flex-column text-end">
        <div>{getRate(quote).toPrecision(6)}</div>
        <div class="text-black-50">
            {quote.rate.toPrecision(6)} plus {(quote.percentFee * 100).toPrecision(2)}% fees
        </div>
    </div>
</ListGroupItem>